import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  // {
  //   id: 'default-helsinki',
  //   predictionPlace: {
  //     address: 'Helsinki, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.29783, 25.25448), new LatLng(59.92248, 24.78287)),
  //   },
  // },
  // {
  //   id: 'default-turku',
  //   predictionPlace: {
  //     address: 'Turku, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.53045, 22.38197), new LatLng(60.33361, 22.06644)),
  //   },
  // },
  // {
  //   id: 'default-tampere',
  //   predictionPlace: {
  //     address: 'Tampere, Finland',
  //     bounds: new LatLngBounds(new LatLng(61.83657, 24.11838), new LatLng(61.42728, 23.5422)),
  //   },
  // },
  // {
  //   id: 'default-oulu',
  //   predictionPlace: {
  //     address: 'Oulu, Finland',
  //     bounds: new LatLngBounds(new LatLng(65.56434, 26.77069), new LatLng(64.8443, 24.11494)),
  //   },
  // },
  // {
  //   id: 'default-ruka',
  //   predictionPlace: {
  //     address: 'Ruka, Finland',
  //     bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
  //   },
  // },
  {
    id: 'default-newyork',
    predictionPlace: {
      address: 'New York',
      bounds: new LatLngBounds(new LatLng(40.917577, -73.700272), new LatLng(40.477399, -74.25909)),
    },
  },
  {
    id: 'default-losangeles',
    predictionPlace: {
      address: 'Los Angeles',
      bounds: new LatLngBounds(
        new LatLng(34.337306, -118.155289), // Northeast corner
        new LatLng(33.703652, -118.668176) // Southwest corner
      ),
    },
  },
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami',
      bounds: new LatLngBounds(
        new LatLng(25.857598, -80.139118), // Northeast corner
        new LatLng(25.709037, -80.315958) // Southwest corner
      ),
    },
  },
  {
    id: 'default-lasvegas',
    predictionPlace: {
      address: 'Las Vegas',
      bounds: new LatLngBounds(
        new LatLng(36.409255, -114.982584), // Northeast corner
        new LatLng(35.995785, -115.360354) // Southwest corner
      ),
    },
  },
  {
    id: 'default-london',
    predictionPlace: {
      address: 'London',
      bounds: new LatLngBounds(new LatLng(51.672343, 0.148271), new LatLng(51.38494, -0.351468)),
    },
  },
];
export default defaultLocations;
