import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';

import { ValidationError } from '../../components';
import css from './FieldPhoneNumberInput.module.css';

const FieldPhoneNumberInputComponent = props => {
  const { rootClassName, className, input, meta, label, id, placeholder, ...rest } = props;
  const { value, onChange, onBlur, onFocus } = input;
  const { touched, error } = meta;
  const [defaultCountry, setDefaultCountry] = useState(null); // Store country code
  const [cssLoaded, setCssLoaded] = useState(false); // Track when CSS is loaded

  const hasError = touched && error;

  // Fetch country code based on IP address
  const fetchUserCountryByIP = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/'); // Fetch data from IPAPI
      const data = await response.json();

      if (data && data.country) {
        let countryCode = data.country;

        setDefaultCountry(countryCode);
      }
    } catch (error) {
      console.error('Error fetching country from IP API:', error);
    }
  };

  useEffect(() => {
    // Dynamically load the phone input styles and set CSS loaded state
    import('react-phone-number-input/style.css').then(() => {
      setCssLoaded(true); // Mark CSS as loaded
    });

    // Fetch the user's country based on IP address
    fetchUserCountryByIP();
  }, []);

  const inputClasses = classNames(css.input, {
    [css.inputError]: hasError,
  });

  // Ensure safe handling of empty value to prevent crashing
  const handleChange = value => {
    onChange(value || ''); // Ensure empty string is passed instead of null
  };

  // Do not render the component until CSS is loaded
  if (!cssLoaded) {
    return null; // Return null or a loading spinner until CSS is ready
  }

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <PhoneInput
        id={id}
        placeholder={placeholder}
        value={value || ''} // Ensure value is an empty string if null
        onChange={handleChange} // Handle changes safely
        onBlur={onBlur}
        onFocus={onFocus}
        className={inputClasses}
        defaultCountry={'US'} // Pass the detected country code
        international
        {...rest}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldPhoneNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  placeholder: 'Enter phone number',
};

FieldPhoneNumberInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  input: shape({
    value: string,
    onChange: func.isRequired,
    onBlur: func.isRequired,
    onFocus: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  label: string,
  id: string,
  placeholder: string,
};

const FieldPhoneNumberInput = props => {
  return <Field component={FieldPhoneNumberInputComponent} {...props} />;
};

export default FieldPhoneNumberInput;
