import React, { useState, Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '../../components';

import css from './FieldTextInput.module.css';

const CONTENT_MAX_LENGTH = 5000;

const FieldTextInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const isTextarea = input.type === 'textarea';

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Manage password visibility for each field independently
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // If it's a password field, use 'text' when visible, 'password' otherwise
  const inputType =
    input.name === 'password' || input.name === 'confirmPassword'
      ? showPassword
        ? 'text'
        : 'password'
      : input.type;

  const inputProps = isTextarea
    ? {
        className: inputRootClass || classNames(css.input, { [css.inputError]: hasError }),
        id,
        rows: 1,
        maxLength: CONTENT_MAX_LENGTH,
        ...input,
        ...rest,
      }
    : {
        className: inputRootClass || classNames(css.input, { [css.inputError]: hasError }),
        id,
        type: inputType, // Dynamic input type based on visibility state
        ...input,
        ...rest,
      };

  const handleDisplayNameChange = e => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, '');
    input.onChange(value); // Call final form's input handler with modified value
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {isTextarea ? (
        <ExpandingTextarea {...inputProps} />
      ) : input.name == 'displayName' ? (
        <div className={css.inputWrapper}>
          <input
            {...inputProps}
            onChange={handleDisplayNameChange} // Apply special onChange for displayName
          />
        </div>
      ) : (
        <div className={css.inputWrapper}>
          <input {...inputProps} type={inputType} />
          {/* Show toggle button if it's a password or confirmPassword input */}
          {(input.name === 'password' || input.name === 'confirmPassword') && (
            <button type="button" onClick={togglePasswordVisibility} className={css.passwordToggle}>
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                    stroke="currentColor"
                  ></path>
                  <circle cx="12" cy="12" r="3" fill="white" stroke="white"></circle>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
                    stroke="currentColor"
                  ></path>
                  <circle cx="12" cy="12" r="3" fill="white" stroke="white"></circle>
                  <line x1="1" y1="1" x2="23" y2="23" stroke="currentColor"></line>
                </svg>
              )}
            </button>
          )}
        </div>
      )}
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextInputComponent} {...this.props} />;
  }
}

export default FieldTextInput;
