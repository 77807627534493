import React, { useState, useEffect } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { ValidationError } from '../../components';
import css from './GoogleMapsAutocomplete.module.css';

const GoogleMapsAutocompleteInput = ({
  input,
  meta = {},
  label,
  form,
  placeholder = 'Enter your city',
  options = { types: ['(cities)'] },
}) => {
  const [inputDisplayValue, setInputDisplayValue] = useState(input.value || ''); // Only store address or city
  const [placeSelected, setPlaceSelected] = useState(false);

  const { touched, error } = meta;

  // Update inputValue when input.value changes
  useEffect(() => {
    setInputDisplayValue(input.value || '');
  }, [input.value]);

  // Handle place selection
  const handlePlaceSelected = place => {
    const establishmentName = place.name ? `${place.name}, ` : ''; // Add space after comma
    const address = place.formatted_address || '';
    const combinedValue = `${establishmentName}${address}`.trim(); // Ensure no leading/trailing spaces

    // Set the combined value in the input field
    input.onChange(combinedValue);

    // Set latitude and longitude in their respective fields using form mutators
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();

    if (lat && lng) {
      form.mutators.setFieldValue('pub_latitude', lat);
      form.mutators.setFieldValue('pub_longitude', lng);
    }
  };

  // Handle manual input change (if needed)
  const handleInputChange = e => {
    const newValue = e.target.value;
    setInputDisplayValue(newValue);
    input.onChange(newValue);
    setPlaceSelected(false);
  };

  // Handle blur event
  const handleBlur = () => {
    input.onBlur();
  };

  // Handle clear button
  const handleClear = () => {
    setInputDisplayValue('');
    input.onChange('');
    onLatLngChange(null, null); // Clear lat/lng when input is cleared
    setPlaceSelected(false);
  };

  const hasError = touched && error;

  return (
    <div className={css.container}>
      {label && <label htmlFor={input.name}>{label}</label>}
      <ReactGoogleAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        id={input.name}
        value={inputDisplayValue}
        onPlaceSelected={handlePlaceSelected}
        onChange={handleInputChange}
        onFocus={input.onFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        options={options}
        className={css.input}
      />
      {placeSelected && (
        <button className={css.clearButton} type="button" onClick={handleClear}>
          Clear
        </button>
      )}
      {hasError && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

export default GoogleMapsAutocompleteInput;
